@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display'), url(../fonts/SFPRODISPLAYREGULAR.OTF) format('truetype');
}
@font-face {
  font-family: 'SF Pro Display-Italic';
  src: local('SF Pro Display-Italic'), url(../fonts/SFPRODISPLAYLIGHTITALIC.OTF) format('truetype');
}
.app-loader-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
}

.app-loader-container .app-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -3rem;
  margin-top: -3rem;
}
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
